import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios, { AxiosRequestConfig } from 'axios';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { QuestionModel } from '../models/question.model';
import pc from 'bit-uibl-data-driver';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private pathQuestionJson: string;
  apiUrl: string;
  uniqueId: any;
  clientId: any;
  state: any;
  carrier: any;
  wnsgetAPPQcalled: any;
  SbligetAPPQcalled: any;
  wnsappquestions: any;
  sbliappquestions: any;
  prequelquestions = []
  question_and_answers = [];
  constructor(private activatedroute: ActivatedRoute, private pc: ProcessCacheService) {
    this.pathQuestionJson = 'http://localhost:3000/question/dynamic_question_sample';
    //this.apiUrl = 'http://localhost:3000/question/dynamic_question_sample';
    this.apiUrl = 'https://uat-app.getmybubble.co/api/question/dynamic_question_sample';
  }

  async getData() {
    debugger;
    this.uniqueId = localStorage.getItem('uniqueIdDQ');
    this.clientId = localStorage.getItem('clientIdDQ');
    this.carrier = localStorage.getItem('carrierDQ');
    this.state = localStorage.getItem('stateDQ');
    let extraquestion = {
      "questionID": 1000000,
      "dependency": 0,
      "question_type": 1,
      "answers": [
        {
          "answer_option_id": 10000001,
          "answer_text": "Emphysema or COPD",
          "answer_option_seq": 1,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000002,
          "answer_text": "Heart disease",
          "answer_option_seq": 2,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000003,
          "answer_text": "Peripheral vascular disease",
          "answer_option_seq": 3,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000004,
          "answer_text": "Human Immunodeficiency Virus (HIV) or Acquired Immunodeficiency Syndrome (AIDS)",
          "answer_option_seq": 4,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000005,
          "answer_text": "Chronic Kidney disease",
          "answer_option_seq": 5,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000006,
          "answer_text": "Liver cirrhosis or disease of the pancreas",
          "answer_option_seq": 6,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000007,
          "answer_text": "Stroke/TIA (mini stroke)",
          "answer_option_seq": 7,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null
        },
        {
          "answer_option_id": 10000008,
          "answer_text": "None of the above",
          "answer_option_seq": 8,
          "answer_category": null,
          "Parent_answer_option_id": null,
          "active_flag": 1,
          "applicable_parent_answer": null,
          "last_key": true
        }
      ],
      "answer_category": 3,
      "active_flag": 1,
      "question_sequence_number": 260,
      "open_field_data_type": 2,
      "question_text": "In the last 5 years has a licensed medical professional diagnosed you with or treated you for any of the following diseases or disorders:",
      "Parent_question_id": null,
      "question_attribute": "",
      "categories": "",
      "applicable_parent_answer": null
    }
    let extraquestion1 = {
      "question_text": "Are you actively serving in the military?",
      "questionID": 2000000,
      "Parent_question_id": null,
      "dependency": 0,
      "question_type": 1,
      "question_attribute": "MILITARY",
      "answer_category": 1,
      "active_flag": 1,
      "categories": "",
      "question_sequence_number": 10,
      "open_field_data_type": 2,
      "applicable_parent_answer": null
    }


    debugger;
    await this.getCommonInput();
    const commonInput = JSON.parse(localStorage.getItem('sbliCommonInput'));
    let rawJson;
    console.log('sbligetAPPQ call commonInput: ', commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);
    if (this.carrier == 'SBLI') {
      if (!this.SbligetAPPQcalled) {
        await quickquote.sbligetAPPQ(commonInput).then(response => {
          debugger;
          console.log('sbligetAPPQ res', response);
          if (response.sbliError) {
          }
          else {
            const sblijson = {
              'id': this.clientId,
              'SBLIgetAPPQCalled': true,
              'SBLIAPPJson': response.appQJson
            };
            this.pc.saveUpdateClientData(sblijson);
            let testjson;
            let json;
            json = JSON.parse(response.appQJson);
            testjson = JSON.parse(json[0].uwqs);
            // testjson.push(extraquestion);
            // testjson.push(extraquestion1);
            console.log('SBLIQuestions', testjson);
            json = JSON.stringify(testjson);
            rawJson = json;
          }
        })
          .catch(error => {
            console.log('sbligetAPPQ Error:', error);
          });
      }
      else {
        let testjson;
        let json;
        json = JSON.parse(this.sbliappquestions);
        testjson = JSON.parse(json[0].uwqs);
        // testjson.push(extraquestion);
        // testjson.push(extraquestion1);
        console.log('SBLIQuestions', testjson);
        json = JSON.stringify(testjson);
        rawJson = json;
      }

    }
    else {
      if (!this.wnsgetAPPQcalled) {
        await quickquote.wnsgetAPPQ(commonInput).then(response => {
          debugger;
          console.log('sbligetAPPQ res', response);
          if (response.sbliError) {
          }
          else {
            const wnsjson = {
              'id': this.clientId,
              'WNSgetAPPQCalled': true,
              'WNSAPPJson': response.appQJson
            };
            this.pc.saveUpdateClientData(wnsjson);
            let testjson;
            let json;
            json = JSON.parse(response.appQJson);
            testjson = JSON.parse(json[0].uwqs);
            testjson.push(extraquestion);
            testjson.push(extraquestion1);
            console.log('W&SQuestions', testjson);
            json = JSON.stringify(testjson);
            rawJson = json;
          }
        })
          .catch(error => {
            console.log('sbligetAPPQ Error:', error);
          });
      }
      else {
        let testjson;
        let json;
        json = JSON.parse(this.wnsappquestions);
        testjson = JSON.parse(json[0].uwqs);
        testjson.push(extraquestion);
        testjson.push(extraquestion1);
        console.log('W&SQuestions', testjson);
        json = JSON.stringify(testjson);
        rawJson = json;
      }
    }


    // const config: AxiosRequestConfig = {
    //   method: 'POST',
    //   url: this.pathQuestionJson,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': '*/*'
    //   },
    //   transformResponse: [
    //     (data) => {
    //       const resp = JSON.parse(data);
    //       if (resp.status === 200) {
    //         return resp.data.questions;
    //       }
    //       return null;
    //     }
    //   ]
    // };

    // const ldResponse = await axios.request<QuestionModel[]>(config);

    const input = {
      'carrier': this.carrier,
      'lob': 'LIFE',
      'state': this.state,
      'question_json': rawJson
    };
    const ldResponse = await axios.post(this.apiUrl, input);
    debugger;
    const { data } = ldResponse.data.data.questions;

    return ldResponse.data.data.questions;
  }

  async getCommonInput() {
    debugger;
    const saveResponse = await this.pc.getAppData(this.clientId);

    const dataFrom = JSON.parse(saveResponse.Payload);
    console.log('get app data: ', dataFrom);
    this.wnsgetAPPQcalled = dataFrom.body.WNSgetAPPQCalled;
    this.SbligetAPPQcalled = dataFrom.body.SBLIgetAPPQCalled;
    this.wnsappquestions = dataFrom.body.WNSAPPJson;
    this.sbliappquestions = dataFrom.body.SBLIAPPJson;
    const commonInput = {
      'lastName': (dataFrom.body.bubble_fname) ? dataFrom.body.bubble_fname : '',
      'firstName': (dataFrom.body.bubble_lname) ? dataFrom.body.bubble_lname : '',
      'middleName': '',
      'addressType': 'PhysicalRisk',
      'address': dataFrom.body.fullAddress,
      'city': dataFrom.body.city_name,
      'gender': dataFrom.body.gender == '1' ? 'M' : 'F',
      'dob': dataFrom.body.dob,
      'term': dataFrom.body.desired_life_term,
      'zip': dataFrom.body.zip,
      'state': dataFrom.body.state,
      'height': dataFrom.body.bubble_heightFt + ' ft ' + dataFrom.body.bubble_heightIn + 'in',
      'weight': dataFrom.body.bubble_weight,
      'tobaccoUse': dataFrom.body.tobacoUser,
      'riskClass': '',
      'coverage': dataFrom.body.desired_life_coverage,
      'premiumFrequency': 'm',
      'email': dataFrom.body.bubble_email,
      'phone': dataFrom.body.bubble_phone
    };
    localStorage.setItem('sbliCommonInput', JSON.stringify(commonInput));
    console.log('sbli commonInput: ', commonInput);
    return true;
  }
}
