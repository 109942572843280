import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from '../shared/process-cache.service';
import { environment } from '../../environments/environment';
import { ToastService } from '../shared/toast.service';
import { LifescoreErrorAlertComponent } from '../components/lifescore-error-alert/lifescore-error-alert.component';
import { ScoreCard } from './types';
import { LifescoreLeadCaptureComponent } from '../lifescore-lead-capture/lifescore-lead-capture.component';
import { ScorecardApiService } from '../shared/scorecard-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { HomeScoreModalComponent } from '../components/home-score-modal/home-score-modal.component';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { SecurityService } from '../shared/security.service';
import { HttpClient } from '@angular/common/http';
import { AddressInputComponent } from '../components/address-input/address-input.component';
const pc = require('bit-uibl-data-driver');
const dataQuery = gql`query( $page_name: String! ){
  pageData(where: { page_name: $page_name }){
    id
    attribute_1_value
    attribute_2_value
    column
    content
    attribute_1_key
    attribute_2_key
    style_category
    data_category
  }
}`;

interface factors {
  factorLabel: string, factorScore: string,
}

const _class = 'HomeScore.Component';

declare let google: any;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const criticalInput = require('./../../../critical-input.json');
@Component({
  selector: 'app-homescore',
  templateUrl: './homescore.component.html',
  styleUrls: ['./homescore.component.css']
})
export class HomeScoreComponent implements OnInit, AfterViewInit {
  @ViewChild(AddressInputComponent, { static: false }) ChildComponent: AddressInputComponent;
  btnDisabled = true;
  scores: ScoreCard = {} as ScoreCard;
  homeScoreLoaded = false;
  @ViewChild(LifescoreLeadCaptureComponent, { static: false }) leadForm: LifescoreLeadCaptureComponent;
  lifeInsuranceAmt: number;
  homeScore = 'true';
  latitude = 37.447719;
  longitude = -122.1642;
  heroForm: any;
  placeId = 'ChIJaaFLW5qHa4cRzr3YClNc7xM';
  addresschangecount = 0;
  addresstype = 'Enter Home address';
  eventIdStrPrefix = 'HomeScore';
  lgLeaflet = false;
  county = 'Santa Clara County';// Denver County";
  stateCode = 'CA';// CO";
  stateLongName = 'California';// Colorado";
  gender = '1';
  age = '25-35';
  fullAddress: any;
  cityStateCode = 'Palo Alto, CA';
  urlSafe: SafeResourceUrl;
  address = {} as any;
  homeScorePercentile = 0;
  requestCallBack = false;
  displayAddressFromParams: any;
  zip: any;
  lifescoreVurl: any;
  ringColor = '00a12f';
  street: any;
  scity: any;
  photoReference: string;
  sstate: any;
  placeImages = [];
  lifeScoreData = {} as any;
  szip: any;
  iframeSrc = 'assets/bubble_states.html?state=CO&stateLongName=Colorado&county=Denver&latitude=39.7789467&longitude=-105.0477277';
  scountry: any;
  addressToSend: string;
  displayAddress = '285 Bryant St Palo Alto CA 94301';// 4529 Winona Ct, Denver, CO 80212, USA";
  cityLongName = 'Palo Alto';// Denver";
  positiveScores: any;
  negativeScores: any;
  addressChanged = false;
  showForm = true;
  values = {};
  sub: any;
  isBtnDisabled = true;
  googleImageInput = {
    id: '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212',
    city: 'Palo Alto',
    stateCode: 'CA',
    zip: '94301'
  };
  lifeScore: any = 0;
  homeInsuranceAmount: number | string = 'No Data';
  isIncompleteAddress: boolean;
  zipcode: any;
  labels = ['Murder', 'Crime', 'Motor Vehicle Theft', 'Larceny', 'Burglary', 'Aggravated Assault', 'Forcible Rape', 'Forcible Robbery', 'Ice Dam Index', 'Frozen Pipe Index', 'Hh Snow Load', 'Enhanced Hail Params', 'Enhanced Wind Params', 'Nuclear Site', 'Enhanced Tornado Params', 'Fault Earthquake', 'Earthquake', 'Enhanced Hazardhub Flood', 'Drought Frequency Index', 'Mold Index', 'Drought', 'Enhanced Lightning Params', 'Fire Protection', 'Wildfire', 'Mudslide Risk', 'Slope Risk', 'Hazardhub Catastrophic Flood', 'Landslide', 'Fema All Flood'];
  imgUrlsMaps = {
    'Mold Index': '../assets/images/homescore/hail-1.svg',
    'Wildfire': '../assets/images/homescore/wildfire.svg',
    'Burglary': '../assets/images/homescore/rice-bowl.svg',
    'Enhanced Wind Params': '../assets/images/homescore/wind-sign.svg',
    'Nuclear Site': '../assets/images/homescore/lightning-bolt-shadow.svg',
    'Enhanced Tornado Params': '../assets/images/homescore/tornado.svg',
    'Enhanced Lightning Params': '../assets/images/homescore/lightning-bolt-shadow.svg',
    'Forcible Rape': '../assets/images/homescore/rice-bowl.svg',
    'Forcible Robbery': '../assets/images/homescore/rice-bowl.svg',
    'Aggravated Assault': '../assets/images/homescore/rice-bowl.svg',
    'Murder': '../assets/images/homescore/rice-bowl.svg',
    'Motor Vehicle Theft': '../assets/images/homescore/rice-bowl.svg',
    'Larceny': '../assets/images/homescore/rice-bowl.svg',
    'Enhanced Hail Params': '../assets/images/homescore/hail-1.svg',
    'Crime': '../assets/images/homescore/rice-bowl.svg',
    'Ice Dam Index': '../assets/images/homescore/hail-1.svg',
    'Frozen Pipe Index': '../assets/images/homescore/hail-1.svg',
    'Hh Snow Load': '../assets/images/homescore/hail-1.svg',
    'Tsunami': '../assets/images/homescore/tsunami.svg',
    'Drought Frequency Index': '../assets/images/homescore/drought.svg',
    'Wind': '../assets/images/homescore/wind-sign.svg',
    'Lightning': '../assets/images/homescore/lightning-bolt-shadow.svg',
    'Slope Risk': '../assets/images/homescore/earthquake.svg',
    'Fire Protection': '../assets/images/homescore/flood.svg',
    'Thunderstorm': '../assets/images/homescore/storm.svg',
    'Tornado': '../assets/images/homescore/tornado.svg',
    'Flood': '../assets/images/homescore/flood.svg',
    'Drought': '../assets/images/homescore/drought.svg',
    'Hail': '../assets/images/homescore/hail-1.svg',
    'Fault Earthquake': '../assets/images/homescore/crack.svg',
    'Wind Region': '../assets/images/homescore/wind-sign.svg',
    'convection_storm': '../assets/images/homescore/wind-sign.svg',
    'Earthquake': '../assets/images/homescore/earthquake.svg',
    'Designated Fault': '../assets/images/homescore/crack.svg',
    'Mudslide Risk': '../assets/images/homescore/flood.svg',
    'Hazardhub Catastrophic Flood': '../assets/images/homescore/flood.svg',
    'Landslide': '../assets/images/homescore/flood.svg',
    'Fema All Flood': '../assets/images/homescore/flood.svg',
  };

  tooltipsMaps = {
    'Mold Index': 'Reflects the likelihood of the formation of mold in homes location',
    'Wildfire': 'Based on percentage of previous wildfires including wild vegetation, vegetation burn points, fire season precipitation, and the like',
    'Crime': 'Reflects the overall likelihood of the occurrence of all types of crimes in the location',
    'Lightning': 'Based on the frequency of ground strike lightning events per year and the probability of lightning strikes in the location',
    'Slope Risk': 'Based on the degree of the slope of land',
    'Tornado': 'Frequency based on past events that include damage and destruction caused by tornadoes',
    'Flood': 'Reflects the overall probability of floods in the location based on the proximity to the nearest water line, nearest flood line, elevation etc',
    'Drought': 'Probability based on drought conditions in the location and its occurrence over the last 20 years',
    'Hail': 'Reflects the previous hail events’ frequency and also includes size of hail, past injuries, and damages caused',
    'Fire Protection': 'Proximity to to water and fire station, among other factors that make the location well-prepared to deal with a fire',

  };
  backgroundcolor: any;
  errormessage: any;
  state: any;
  city: any;
  addresscomponent: { formatted_address: string; policyOwnerInfo: {}; };
  cityName: string;
  type = 0;

  /**https://api.worldpostallocations.com/pincode?postalcode=<your_pin_code_eg: 560094>&countrycode=<two_digit_country_code_eg:IN>
   *
   * @param property Label for the Underlying Cause
   */
  getImageUrl(property: string) {
    let src = '';
    Object.keys(this.imgUrlsMaps).forEach((key) => { if (property.includes(key)) { src = this.imgUrlsMaps[key]; } });
    return src;
  }
  gettooltip(property: string) {
    let tooltip = '';
    Object.keys(this.tooltipsMaps).forEach((key) => { if (property.includes(key)) { tooltip = this.tooltipsMaps[key]; } });
    return tooltip;
  }
  addSeoData() {

    const head = document.querySelector('head');
    const fragment = new DocumentFragment();

    const metaData = [{
      name: 'robots',
      content: 'index'
    },
    {
      name: 'robots',
      content: 'follow'
    },
    {
      name: 'keywords',
      content: 'home insurance score, homeowners insurance score, home insurance score chart, homeowners insurance for bad credit, credit score for home insurance, homeowners insurance score'
    },
    {
      name: 'title',
      content: 'Homeowners Insurance Score- Check Credit Score for Home Insurance Online'
    },
    {
      name: 'og:title',
      content: 'Homeowners Insurance Score- Check Credit Score for Home Insurance Online'
    },
    {
      name: 'og:description',
      content: 'Bubble HomeScore ™ reflects at a glance, in one label, the potential threat from hazards that can cause damage to your property, its valuation and to the safety and security of your personal belongings.'
    },
    {
      name: 'description',
      content: 'Bubble HomeScore ™ reflects at a glance, in one label, the potential threat from hazards that can cause damage to your property, its valuation and to the safety and security of your personal belongings.'
    }];

    const desc = document.querySelector('meta[name=description]');
    desc?.remove();

    metaData.forEach(({ name, content }) => {
      const meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      fragment.appendChild(meta);
    });

    document.title = 'Homeowners Insurance Score- Check Credit Score for Home Insurance Online';

    const link = document.createElement('link');
    link.rel = 'canonical';

    const url = window.location.pathname;

    link.href = `https://app.getmybubble.com${url}`;

    fragment.appendChild(link);

    head.appendChild(fragment);
  }

  dataMap = {
    'wildfire.scoredput': 'Wildfire',
    'fault_earthquake.scoredput': 'Designated Fault',
    'earthquake.scoredput': 'Earthquake',
    'fema_all_flood.scoredput': 'FEMA Flood Grade',
    'enhanced_hazardhub_flood.scoredput': 'FEMA Flood Grade',
    'enhanced_hail_params.scoredput': 'Hail',
    'drought_frequency_index.scoredput': 'Drought',
    'enhanced_tornado_params.scoredput': 'Tornado',
    'burglary.scoredput': 'Burglary',
    'Home Score Labelsdput': 'Home Score',
    'Home Score Percentiledput': 'homeScorePercentile',
    'crime.scoredput': 'Burglary',
    'enhanced_wind_params.scoredput': 'Wind',
    'enhanced_lightning_params.scoredput': 'Lightning',
    'enhanced_hazardhub_flood.score': 'FEMA Flood Grade',
    'convection_storm.scoredput': 'Storm'
  };

  /**
   * For HomeScore Data with A or B grade
   *
   * @constant
   * @type {string[]}
   */
  whyItsSafeProperties: Set<string> = new Set();

  /**
   * For HomeScore Data with C, D or E grade
   *
   * @constant
   * @type {string[]}
   */
  propertiesToLookOut: Set<string> = new Set();


  divShowHide = {
    div1: false,
    div2: false,
    div3: true,
    circularScore: false,
    scoreQuoteText: false,
    keyPoints: false,
    positiveScore: false,
    negativeScore: false,
    placePhotos: true,
    keyHighlights: true,
    customizebutton: true,
    customizebuttonV2: true
  };

  // Map homeScore with colors
  mapClasses = {
    'A': 'green',
    'B': 'dark-green',
    'C': 'orange',
    'D': 'dark-red',
    'E': 'red',
    'F': 'red',
  };
  navigationToLifeScore: string;

  getContent() {
    this.apollo.watchQuery<any>({
      query: dataQuery,
      variables: {
        page_name: 'home_insurance',
      }
    }).valueChanges
      .subscribe(
        ({ data }) => {
          const { pageData } = data;
          console.log(data, 'GraphQL');
          const allData = pageData.map(e => {
            return {
              content: e.content,
              section: e.data_category,
              style: e.style_category,
              column: e.column,
              src: e.attribute_1_value,
            };
          });
        },
        (error) => {
          console.log(error, 'GraphQL', 'Error');
        });
  }

  goToPropertyDetails() {
    window.location.href = `prefillResumeFlowHoi?full_address=${this.displayAddress}&zip=${this.zip}&city=${this.cityLongName}&state=${this.stateCode}&country=USA&address=${this.street}`;
  }

  /**
   * Text to show for a given homescore
   */
  textToShow = {
    'A': 'Excellent',
    'B': 'Good',
    'C': 'Fair',
    'D': 'Poor',
    'E': 'Bad'
  };

  homeURL: string;
  showLoader: boolean;

  constructor(private pc: ProcessCacheService,
    private modalService: NgbModal,
    public toastService: ToastService,
    private security: SecurityService,
    private route: ActivatedRoute,
    private scoreCardApi: ScorecardApiService,
    private router: Router,
    private apollo: Apollo,
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private utilityService: UtilityFunctionsService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.leadForm.authorised = true;
    }, 1000);
  }


  getZipBasedScore() {
    const zip = (document.getElementById('input-address-search') as HTMLInputElement).value;

    console.log(zip, 'ZIP CODE');
    this.getHomeScore(zip);
    this.updatePropertyData(zip);
  }

  getZipCode() {
    const zip: any = (document.getElementById('input-address-search') as HTMLInputElement).value;
    if (!isNaN(zip) && zip.length === 4) {
      this.getZipBasedScore();
    }
  }

  getCSSClass() {

    this.textToShow = {
      /* 'A': 'Excellent',
      'B': 'Good',
      'C': 'Fair',
      'D': 'Poor',
      'E': 'Bad' */
      'A': 'A',
      'B': 'B',
      'C': 'C',
      'D': 'D',
      'E': 'E'
    };
    if (this.homeScore == 'A' || this.homeScore == 'B' || this.homeScore == 'C' || this.homeScore == 'D' || this.homeScore == 'E') {
      if (this.textToShow[this.homeScore] == 'Excellent' || this.homeScore == 'A') {
        this.backgroundcolor = 'rgb(0, 161, 47)';
      }
      else if (this.textToShow[this.homeScore] == 'Good' || this.homeScore == 'B') {
        this.backgroundcolor = 'rgb(24, 104, 47)';
      }
      else if (this.textToShow[this.homeScore] == 'Fair' || this.homeScore == 'C') {
        this.backgroundcolor = 'rgb(255, 170, 50)';
      }
      else if (this.textToShow[this.homeScore] == 'Poor' || this.homeScore == 'D') {
        this.backgroundcolor = 'rgb(160, 38, 38)';
      }
      else if (this.textToShow[this.homeScore] == 'Bad' || this.homeScore == 'E') {
        this.backgroundcolor = 'rgb(231, 0, 0)';
      }

    }
    return `score--text ${this.mapClasses[this.homeScore] || 'no-data'}`;
  }

  changeLeadFormLabels() {
    let i = 0;
    const endInterval = setInterval(() => {
      i++;
      const form = document.querySelector('.lead-form-inputs');
      const labels = form && form.querySelectorAll('label');
      labels && labels.forEach(label => label.innerHTML = label.innerHTML + '<sup>*</sup>');
      labels && clearInterval(endInterval);
      i === 5 && clearInterval(endInterval);
    }, 200);
  }

  changeButtonText() {
    let i = 0;
    const endInterval = setInterval(() => {
      i++;
      i === 5 && clearInterval(endInterval);
      (document.querySelector('.getmybubble-primary-orange-btn') as HTMLElement).innerText = 'Call Me';
      clearInterval(endInterval);
    }, 200);
  }

  ngOnInit() {
    this.ChildComponent;
    this.leadForm;
    //this.getaddressbypin()
    this.addSeoData();
    this.homeURL = this.pc.envCred.url;
    //this.getContent();
    // if (!this.pc.clientId) {
    //   this.pc.getClientId();
    // }

    this.changeLeadFormLabels();
    this.changeButtonText();

    this.route.queryParams.subscribe(params => {

      /**
     * Removing address from URL with `this.router.navigate`
     * will trigger the method
     */
      if (this.displayAddressFromParams) {
        console.log('Params Read', _class);
        return;
      }

      this.displayAddressFromParams = params && params['address'];
      //   if( !this.pc.fullAddress ) {
      //     this.pc.fullAddress = this.displayAddressFromParams;
      //   }

      //   if( ! this.pc.uniqueId ) {
      //     this.pc.setUniqueIdAndInvokeProcess( 'QuoteFlow' );
      //   }
      const uniqueId = localStorage.getItem('uniqueId');
      // Only check for client Id if URL Params has address.
      if (uniqueId && params && params['address']) {
        this.pc.uniqueId = uniqueId;
        console.log(this.pc.uniqueId, 'uniqueId');
      }
      console.log(this.displayAddressFromParams, this.pc.clientId, _class);

      if (this.displayAddressFromParams) {
        this.changeLoaderState(true, 'Loaded Address From Params');

        const address = this.displayAddressFromParams.replace('-', '+');
        this.displayAddress = address;

        console.log('displayAddressFromParams: ', address, _class);

        this.pc.getGoogleAddressDetails(address).then(
          response => {
            const addressDetails = response.data.results[0];
            this.pc.fullAddress = addressDetails.formatted_address;
            this.handleAddressChange(addressDetails);
            // setTimeout(() => {
            //   this.pc.getCostFromCache('homeInsuranceAmount');
            // }, 5000);
          }
        ).catch((error) => {
          this.getUserLocation();
          console.log('error: ', address, _class);
          console.log(error.toString());
        });
      } else {
        console.log('no address: ', _class);
        this.getUserLocation();
      }
      this.lgLeaflet = true;
      setTimeout(() => {
        // Removing address from URL
        this.router.navigate(['/homescore']);
      }, 2000);
    });

    this.getLifeScore(this.county, this.stateCode);

    setTimeout(() => {
      this.navigationToLifeScore = `/lifescoreV/${this.pc.uniqueId}/${this.pc.clientId}/${this.displayAddress}/life`;
    }, 3000);

    // this.sub = this.pc.castpredictedScores.subscribe( data => {
    //   if ( this.displayAddress === this.pc.fullAddress ) {
    //     this.updateHomeInsuranceAmount( data.homeInsuranceAmount, 'this.displayAddress === this.pc.fullAddress'  )
    //   }
    // } );  if(this.textToShow['homeScore']=='Excellent'){ }


  }




  updateHomeInsuranceAmount(value: number | string, event: string) {
    this.homeInsuranceAmount = value;
    console.log('homeInsurance Updated For', this.displayAddress, 'value', value, event);
  }

  geocodeLatLng(
    geocoder: google.maps.Geocoder,
  ) {
    const latlng = {
      lat: this.latitude,
      lng: this.longitude
    };
    geocoder.geocode(
      { location: latlng },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if ('OK' === status) {
          if (results[0]) {
            //  map.setZoom(11);
            this.changeLoaderState(true, 'Getting Default Address');
            this.handleAddressChange(results[0] as unknown as Address);
            // document.getElementById( 'input-address-search' ).focus();
            setTimeout(() => {
              document.getElementById('input-address-search').blur();
            }, 1000);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude, this.longitude, 'long, lat');
        const geocoder = new google.maps.Geocoder();
        this.geocodeLatLng(geocoder);
      }, () => {
        console.log('Permission denied');
        this.loadDefaultAddress();
      }, { timeout: 10000 });
    }
  }
  openErrorModal(alertType: string, message = '') {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: message
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    if ('ZERO_VALUE' == alertType) {
      message = 'We can\'t give you a Bubble LifeScore &trade; for that particular location just yet. Please check back later';
    } else if ('INVALID_ADDRESS' == alertType) {
      message = 'Bubble HomeScore is only available for locations within the United States of America';
    }

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: message,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      this.loadDefaultAddress();
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  loadDefaultAddress() {
    this.latitude = 37.447719;
    this.longitude = -122.1642;
    this.placeId = 'ChIJaaFLW5qHa4cRzr3YClNc7xM';
    this.displayAddress = '285 Bryant St Palo Alto CA 94301'; // 4529 Winona Ct, Denver, CO 80212, USA";
    this.stateCode = 'CA';
    this.cityStateCode = 'Palo Alto, CA';
    this.stateLongName = 'California';
    this.county = 'Santa Clara County';
    this.cityLongName = 'Palo Alto';
    this.scity = this.cityLongName;
    this.sstate = this.stateCode;
    this.szip = '94301';
    this.scountry = 'USA';
    this.addressToSend = `${this.scity}-${this.sstate}-${this.szip}-${this.scountry}`;
    this.googleImageInput = {
      id: '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212',
      city: 'Palo Alto',
      stateCode: 'CA',
      zip: '94301'
    };
    console.log('Getting Default Address', _class);
    this.pageLoadInvoke();
  }

  pageLoadInvoke() {
    // this.pc.lat = this.latitude;
    // this.pc.lng = this.longitude;
    // this.pc.placeId = this.placeId;
    // this.pc.initCaching(this.displayAddress, this.eventIdStrPrefix);
    // this.pc.uniqueId = '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212';
    setTimeout(() => {
      this.pc.invokeLambda(this.googleImageInput);
      this.getLocationImages();
      this.geocodeLatLng(new google.maps.Geocoder());
    }, 1000);
  }

  getLocationImages() {
    console.log('getLocationImages 1');
    if (!this.divShowHide.placePhotos) {
      console.log('getLocationImages 2');
      let count = 1;
      this.photoReference = '';

      const interval = setInterval(() => {
        if (3 > count && !this.photoReference) {
          this.pc.getPhotosFromCache('place_id');
          this.pc.getPhotosFromCache('separator');
          this.pc.getPhotosFromCache('photos');
          count++;
        } else {
          clearInterval(interval);
          if (this.photoReference) {
            const tmpImages = [];
            this.placeImages = this.photoReference.split('__-__').slice(0, 4);
            for (let i = 0; i < this.placeImages.length; i++) {
              tmpImages[i] = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=127&maxheight=109&key=${environment.googleMapApiKey}&photoreference=${this.placeImages[i]}`;
            }
            this.placeImages = tmpImages;
            console.log(this.placeImages);
          }
        }
      }, 1000);
    }
  }

  lifeScorePageMethod() {
    this.scoreCardApi.castLifescore.subscribe(
      (data: any) => {
        this.lifeScoreData = data;
        if (this.lifeScoreData.scores) {
          this.positiveScores = this.lifeScoreData.scores.filter((item) => 'positive' == item.extras.type && criticalInput.LIFESCORE_CARDS_POSITIVE.includes(item.name));
          this.negativeScores = this.lifeScoreData.scores.filter((item) => 'negative' == item.extras.type && criticalInput.LIFESCORE_CARDS_NEGATIVE.includes(item.name));
        }
        this.lifeScore = this.lifeScoreData.lifeScore;
      });
  }

  moveHomeScoreNeedle(homeScorePercentile: number) {
    // 0 percentile is at 90 deg rotation and 100 percentile is at 360 deg rotation.
    document.getElementById('homescore-arrow').style.display = 'inline';
    let rotation = 90 + (270 / 100) * homeScorePercentile;

    const rotationBounds = {
      'A': [359, 323],
      'B': [301, 282],
      'C': [267, 210],
      'D': [194, 152],
      'E': [136, 103]
    };

    // if rotation for HomeScore goes less than lower bound
    rotation = Math.max(rotation, rotationBounds[this.homeScore][1]);

    // if rotation for HomeScore goes less than lower bound
    rotation = Math.min(rotation, rotationBounds[this.homeScore][0]);

    /* Rotations of arrow upper bounds
  [359, 307, 267, 194, 136];
  Rotations of arrow lower bounds
  [323, 282, 210, 152, 103];
  */
    document.getElementById('homescore-arrow').style.transform = `rotate(${rotation}deg)`;
  }

  getLifeScore(county: string, stateCode: string) {
    this.lifeScorePageMethod();
    this.scoreCardApi.newLifeScoreAPI('1', '25-35', this.zip);
  }

  callback(divtype: string | any) {
    this.showmap = divtype;

    if ('thankyou' === divtype) {
      this.showForm = false;
    }

    const myModal = document.getElementById('myModal');
    const makeBlur = document.getElementById('makeblur');
    if ('thankyou' == this.showmap) {
      if (myModal) myModal.style.display = 'block';
      if (makeBlur) makeBlur.style.filter = 'blur(10px)';
    }

    if ('map' == this.showmap || 'form' == this.showmap) {
      if (window && 420 > window.innerWidth)
        document.body.style.position = 'fixed';
      if (myModal) myModal.style.display = 'none';
      if (makeBlur) makeBlur.style.filter = 'none';
    }
  }

  public handleAddressSelect(address) {
    this.type = 0;
    this.errormessage = '';
    this.isBtnDisabled = false;
    this.address = address;
    this.parseGoogleAddress(this.address);
  }

  public handleAddressChange(address: Address) {
    if (!address.geometry || !address.geometry.location) {
      this.changeLoaderState(false, 'handleAddresschange No Address');
      this.updateHomeInsuranceAmount('No Data', 'Address undefined');
      return;
    } else {
      this.updateHomeInsuranceAmount('Getting Data', 'Data Fetch');
    }

    console.log('handleAddressChange', address);
    if ('function' == typeof address.geometry.location.lat) {
      this.latitude = address.geometry.location.lat();
      this.longitude = address.geometry.location.lng();
    }
    this.placeId = address.place_id;

    this.pc.lat = this.latitude;
    this.pc.lng = this.longitude;
    this.pc.placeId = this.placeId;

    /* console.log("@@---->", address.geometry.location);*/
    // console.log('@@---->latitude', this.latitude);
    //console.log('@@---->longitude', this.longitude);
    this.fullAddress = address;
    // console.log('formatted_address ', address.formatted_address);
    // console.log('JSON.stringify:address.address_components ', JSON.stringify(address.address_components, null, 2));
    this.displayAddress = this.fullAddress.formatted_address;
    this.pc.fullAddress = this.displayAddress;
    console.log('handleAddressChange displayAddress', this.displayAddress);
    this.addressChanged = true;
    const eventOptionsPart1 = {
      'path': 'homeScore',
      'page_title': 'homeScore', 'url': window.location.href
    };
    this.utilityService.eventWrapped(eventOptionsPart1, 'addressClick', 'event', `lifescore_page: addressChangedTo: ${this.displayAddress} `);


    //   this.pc.initCaching(this.fullAddress.formatted_address, this.eventIdStrPrefix);

    let county = '';//'Autauga County';
    let stateCodeLocal = '';//'AL';
    let city = '';//'Autauga';
    let cityLongName = '';//'Autauga';
    let zip = '';
    let countyLongName = '';
    let street_address = '', street_ln = '', route_sn = '';
    let country = '';
    for (let i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types) {
        if (address.address_components[i].types.includes('street_number')) {
          street_ln = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('route')) {
          route_sn = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_2')) {
          county = address.address_components[i].short_name;
          countyLongName = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('locality')) {
          city = address.address_components[i].short_name;
          cityLongName = address.address_components[i].long_name;
          this.cityName = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_1')) {
          stateCodeLocal = address.address_components[i].short_name;
          this.stateLongName = address.address_components[i].long_name;
          this.stateCode = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('postal_code')) {
          zip = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('country')) {
          country = address.address_components[i].short_name;
        }
      }
    }

    street_address = street_ln + ' ' + route_sn;

    // if ('' == county) {
    //   this.changeLoaderState(false, 'Address Details not available for searched text');
    //   this.openErrorModal('', 'Address Details not available for searched text');
    //   return;
    // }
    if ('US' !== country) {
      this.changeLoaderState(false, 'INVALID_ADDRESS');
      this.openErrorModal('INVALID_ADDRESS', '');
      return;
    }
    /*
      let swData = {
      "data_category": "getMinimalHomeQuoteFromStillWater",
      "fetchViaAPI": "true",
      "dataCategory": "StillWater_QuickQuote",
      "uid": uniqueId,
      "lastname": "T",
      "firstname": "Anonymous",
      "streetaddress": google_address.street_number_ln + " " + google_address.route_sn, //"353 Portland Cir",
      "city": google_address.locality_ln,
      "state": google_address.administrative_area_level_1_sn,
      "zipcode": google_address.postal_code_sn
    };
    */

    const swData = {
      'data_category': 'getMinimalHomeQuoteFromStillWater',
      'fetchViaAPI': 'true',
      'dataCategory': 'StillWater_QuickQuote',
      'lastname': 'T',
      'uid': this.pc.uniqueId,
      'firstname': 'Anonymous',
      'streetaddress': street_address,
      'city': cityLongName,
      'state': stateCodeLocal,
      'zipcode': zip,
      'resultformat': 'yes'
    };

    this.navigationToLifeScore = `/lifescoreV/${this.pc.uniqueId}/${this.pc.clientId}/${this.displayAddress}/life`;
    this.updatePropertyData(swData.zipcode);

    //this.pc.getCostFromCache('homeInsuranceAmount');

    console.log('After county state validation');
    localStorage.setItem('bubble-zip', zip);
    localStorage.setItem('bubble-street', street_address);
    localStorage.setItem('bubble-city', cityLongName);

    zip && this.getHomeScore(zip);
    //(zip);
    this.county = county;
    if (zip) {
      this.zip = zip;
    }
    this.street = street_address;
    this.stateCode = stateCodeLocal;
    this.getLifeScore(countyLongName, this.stateCode);
    // this.pc.getCostFromCache('homeInsuranceAmount');
    this.googleImageInput.zip = zip;
    this.googleImageInput.stateCode = stateCodeLocal;
    this.googleImageInput.city = city;
    // county = county.replace('County', '').replace(/\s/g, '');

    console.log('County:', county, 'State:', stateCodeLocal, '# city: ', city, '#cityLongName: ', cityLongName);
    this.cityStateCode = `${city}, ${stateCodeLocal} `;
    if (!city) this.cityStateCode = `${county}, ${stateCodeLocal} `;
    this.cityLongName = cityLongName;

    console.log('this.googleImageInput: ', this.googleImageInput);
    setTimeout(() => {
      this.pc.invokeLambda(this.googleImageInput);
      this.getLocationImages();
    }, 1000);
  }

  async updatePropertyData(zip) {
    try {
      const response = await fetch('https://api2.getmybubble.io/fetch1', {
        method: 'POST',
        'body': JSON.stringify({
          data_category: 'avg_home_premium',
          zip
        })
      });

      const {
        yearlyPremium,
        error
      } = await response.json();
      console.log(zip, yearlyPremium, 'FROM BUBBLE DB');
      if (yearlyPremium) {
        this.updateHomeInsuranceAmount(((yearlyPremium / 12).toFixed(0)), 'Recieved Quote');
      } else {
        this.updateHomeInsuranceAmount('No Data', error.toString());
      }
    } catch (error) {
      this.updateHomeInsuranceAmount('No Data', error.toString());
    }
  }

  openModal(event: InputEvent, type: string) {
    event.preventDefault();
    if ('homeScore' === type) {
      this.modalService.open(HomeScoreModalComponent);
    }
  }

  async addressSubmitted(x: number) {
    this.type = x;
    if (x) {
      this.zip = this.zipcode;
      this.getHomeScore(this.zipcode);
      this.updatePropertyData(this.zipcode);
    }

    this.changeLoaderState(true, x + ' Address Submitted');
    //await this.getHomeScore(this.zipcode);
    this.handleAddressChange(this.address);
    this.pc.uniqueId = pc.getUniqueId4Property(this.pc.clientId, this.fullAddress.formatted_address);
    console.log('Address Submitted', this.address);
    this.isBtnDisabled = true;
  }

  getClass(value: string): string {
    if (!(true === this.homeScoreLoaded) || !this.values[value]) {
      return 'ring green';
    }
    return 'ring ' + this.mapClasses[this.values[value]];
  }

  changeLoaderState(value: boolean, event: string) {
    debugger;
    this.showLoader = value;
  }

  lifeScoreColor(data) {
    this.ringColor = data;
    console.log('lifeScoreColor: ', data);
  }

  setScores(factorsArray: factors[]) {
    const plus = ['A', 'B'];
    this.whyItsSafeProperties = new Set();
    this.propertiesToLookOut = new Set();
    factorsArray.forEach(({ factorLabel, factorScore }) => {
      if (factorLabel == 'Enhanced Hazardhub Flood') {
        factorLabel = 'Flood Risk';
      }
      if (plus.includes(factorScore)) {
        this.whyItsSafeProperties.add(factorLabel);
      } else {
        this.propertiesToLookOut.add(factorLabel);
      }
      this.values[factorLabel] = factorScore;
    });
  }

  getHomeScore = async (zip: string) => {
    this.changeLoaderState(true, 'HomeScore Data Not Found');
    const apiKey = 'd0ecb36d31ffbd0bf76ab7f37a2aef2d';
    const res = await fetch(`${environment.Nest_API_URL}/scores/home?zipCode=${zip}`,
      {
        headers: { 'x-api-key': apiKey }
      }
    );
    const data = await res.json();
    if (!data.message) {
      this.homeScore = data['Home Score Label'];
      this.moveHomeScoreNeedle(data['Home Score Percentile']);
      this.setScores(data.factors);
      this.homeScoreLoaded = true;
      this.lgLeaflet = true;
      this.zipcode = zip;
      this.zip = zip;
      this.homeScorePercentile = data['Home Score Percentile'];
      this.moveHomeScoreNeedle(this.homeScorePercentile);
      this.changeLoaderState(false, 'Loaded HomeScore');
      console.log(this.homeScore, 'home score');
      console.log(this.scores, 'HomeScoreValues');
      console.log(this.values, 'HomeScoreValues');
    } else {
      this.homeScoreLoaded = false;
      this.changeLoaderState(false, 'HomeScore Data Not Found');
      document.getElementById('homescore-arrow').style.display = 'none';
      this.homeScore = '';
      this.homeScorePercentile = null;
    }
  };


  setHomeScoreDecrypted(obj: ScoreCard) {
    const METHOD_NAME = 'setHomeScoreDecrypted';

    this.homeScore = obj['Home Score Labelsdput'];
    this.textToShow = {
      'A': 'Excellent',
      'B': 'Good',
      'C': 'Fair',
      'D': 'Poor',
      'E': 'Bad'
    };
    if (this.homeScore == 'A' || this.homeScore == 'B' || this.homeScore == 'C' || this.homeScore == 'D' || this.homeScore == 'E') {
      if (this.textToShow[this.homeScore] == 'Excellent') {
        this.backgroundcolor = 'rgb(0, 161, 47);';
      }
      else if (this.textToShow[this.homeScore] == 'Good') {
        this.backgroundcolor = 'rgb(24, 104, 47);';
      }
      else if (this.textToShow[this.homeScore] == 'Fair') {
        this.backgroundcolor = 'rgb(255, 170, 50);';
      }
      else if (this.textToShow[this.homeScore] == 'Poor') {
        this.backgroundcolor = 'rgb(160, 38, 38);';
      }
      else if (this.textToShow[this.homeScore] == 'Bad') {
        this.backgroundcolor = 'rgb(231, 0, 0);';
      }
      this.lgLeaflet = true;
    }
    this.homeScorePercentile = +obj['Home Score Percentiledput'];
    obj['Home Score Labelsdput'] = undefined;
    obj['Home Score Percentiledput'] = undefined;
    const labels = [];
    Object.entries(obj).forEach(([key, value]: [key: string, value: string]) => {
      if (value && !value.startsWith('1')) {
        let label = '';
        console.log('homeScoreDecryptedKey', key, 'value', value, METHOD_NAME);
        if (key.endsWith('.scoredput')) {
          // Capitalize Labels
          label = key.replace('.scoredput', '').replace(/_/g, ' ').split(' ').map(e => e.charAt(0).toUpperCase() + e.substring(1)).join(' ');
          console.log('homeScoreDecryptedKey Label', label, METHOD_NAME);
          if (this.imgUrlsMaps[label]) {
            labels.push(label);
            if (value === 'A' || value === 'B') {
              this.whyItsSafeProperties.add(label);
            } else {
              this.propertiesToLookOut.add(label);
            }
          }

          this.values[label] = value;
        }
      }
    });

    console.log(labels, 'HOMESCORE LABELS');
    console.log(this.propertiesToLookOut, 'propertyToLookOutFor', METHOD_NAME);
    console.log('whyIt\'sSafe', this.whyItsSafeProperties, METHOD_NAME);

    this.moveHomeScoreNeedle(this.homeScorePercentile);
    this.changeLoaderState(false, 'Loaded HomeScore');
    this.homeScoreLoaded = true;
    console.log('HomeScore Loaded', true);
    this.lgLeaflet = true;
  }

  setHomeScores(obj: ScoreCard) {
    console.log('Setting Home Scores', this.security);
    Object.entries(obj).forEach(
      ([key, value]: [key: string, value: string]) => {
        const decrypted = this.security.decryptCodes(value, '');
        console.log(decrypted, 'decrypted');
        const decrypted1 = this.security.decryptCodes(value.substring(1), '');
        console.log(decrypted1, 'decrypted');
        if (!value.startsWith('1')) {
          this.scores[key] = value;
          console.log('key: ', key, 'key to map', this.dataMap[key], 'value ', value);
          console.log(key, 'value', value);
          if (value && this.dataMap[key]) {
            console.log(this.dataMap[key], this.values[this.dataMap[key]]);
            this.values[this.dataMap[key]] = value;
          }
        }
      });
    this.homeScoreLoaded = true;
    console.log('HomeScore Loaded', true);
    this.lgLeaflet = true;
    this.homeScore = this.values['Home Score'];
    this.homeScorePercentile = this.values['homeScorePercentile'];
    this.moveHomeScoreNeedle(this.homeScorePercentile);
    this.changeLoaderState(false, 'Loaded HomeScore');
    console.log(this.homeScore, 'home score');
    console.log(this.scores, 'HomeScoreValues');
    console.log(this.values, 'HomeScoreValues');
  }

  showmap = 'map';

  // async getHomeScoreData(zip: string) {
  //   const zipEncrypted = this.security.encryptCodes(zip);
  //   console.log(zip, `${this.pc.envCred.api2Domain} ? data_category = HomeScore & version=v5 & get1=${zipEncrypted}`, _class);
  //   console.log('Getting HomeScore');

  //   // Delete HomeScore Underlying Causes of Previous Property.
  //   this.propertiesToLookOut = new Set<string>();
  //   this.whyItsSafeProperties = new Set<string>();

  //   try {
  //     const response = await fetch(`${this.pc.envCred.api2Domain} ? data_category = HomeScore & version=v5 & get1=${zipEncrypted}`);
  //     let { output } = await response.json();

  //     // let output: any = { 'Home Score Labels':  "1103", 'Home Score Labelsdput':  "C", 'Home Score Percentile':  "1088135083124152103168169085105087", 'Home Score Percentiledput':  "40.04796163", 'Home_scores':  "1084133089126157105164170089105090", 'Home_scoresdput':  "0.429957566", 'convection_storm.score':  "1146184147", 'convection_storm.scoredput':  "", 'crime.score':  "1103", 'crime.scoredput':  "C", 'enhanced_hazardhub_flood.score':  "1101", 'enhanced_hazardhub_flood.scoredput':  "A", 'enhanced_wind_params.score':  "1146184147", 'enhanced_wind_params.scoredput':  "", 'fire_protection.score':  "1101", 'fire_protection.scoredput':  "A", 'hazardhub_catastrophic_flood.score':  "1146184147", 'hazardhub_catastrophic_flood.scoredput':  "", 'ice_dam_index.score':  "1146184147", 'ice_dam_index.scoredput':  "", 'slope_risk.score':  "1101", 'slope_risk.scoredput':  "A", 'wildfire.score':  "1102", 'wildfire.scoredput':  "B",}

  //     output = JSON.parse(output) as ScoreCard;
  //     console.log(output, _class, 'HomeScore Data');
  //     //this.setHomeScores( output );
  //     this.setHomeScoreDecrypted(output);
  //   } catch (e) {
  //     this.homeScoreLoaded = false;
  //     console.log('HomeScore Loaded', false);
  //     this.changeLoaderState(false, 'HomeScore Data Not Found');
  //     document.getElementById('homescore-arrow').style.display = 'none';
  //     this.homeScore = '';
  //     console.log(e, _class, 'HomeScore Data Error');
  //   }
  // }

  public getaddressbypin() {
    if (this.zipcode.length > 4) {
      this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.zipcode}&key=AIzaSyB_DcLM8ITEURJDmtF5T8CQrQBCOqkCc3o`).subscribe(data => {
        if (data != null) {
          const results = data['results'];
          if (results.length > 0) {
            this.errormessage = '';
            const zipCodeValue = this.zipcode;
            if (!zipCodeValue) {
              //
            } else {
              const formatted_address = results[0].formatted_address;
              this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${formatted_address}&key=AIzaSyB_DcLM8ITEURJDmtF5T8CQrQBCOqkCc3o`).subscribe(data1 => {
                const results1 = data1['results'];
                this.address = results1[0];
                setTimeout(() => {
                  // this.handleAddressSelect(this.address);
                  this.parseGoogleAddress(results1[0]);
                  this.type = 1;
                  this.isBtnDisabled = false;
                }, 1000);
              });
            }
          }
          else {
            this.errormessage = 'please enter the valid zipcode';
            this.isBtnDisabled = true;
          }
        }
      });

    }

  }
  async parseGoogleAddress(address) {
    this.displayAddress = address.formatted_address.replace(/#/g, '');
    const google_address = {
      'formatted_address': '',
      'policyOwnerInfo': {}
    };
    const results = [];
    results[0] = address;
    console.log('results address', results, results[0].address_components);
    for (let i = 0; i < results[0].address_components.length; i++) {
      const type = results[0].address_components[i].types[0];
      switch (type) {
      case 'street_number':
        google_address['street_number_ln'] = results[0].address_components[i].long_name;
        google_address['street_number_ln'] = results[0].address_components[i].short_name;
        break;
      case 'route':
        google_address['route_ln'] = results[0].address_components[i].long_name;
        google_address['route_sn'] = results[0].address_components[i].short_name;
        break;
      case 'neighborhood':
        google_address['neighborhood_ln'] = results[0].address_components[i].long_name;
        google_address['neighborhood_sn'] = results[0].address_components[i].short_name;
        // addressJson.Addr1[2] = results[0].address_components[i].short_name;
        break;
      case 'locality':
        google_address['locality_ln'] = results[0].address_components[i].long_name;
        google_address['locality_sn'] = results[0].address_components[i].short_name;
        this.city = results[0].address_components[i].short_name;
        break;
      case 'administrative_area_level_2':
        google_address['administrative_area_level_2_ln'] = results[0].address_components[i].long_name;
        google_address['administrative_area_level_2_sn'] = results[0].address_components[i].short_name;
        break;
      case 'administrative_area_level_1':
        google_address['administrative_area_level_1_ln'] = results[0].address_components[i].long_name;
        google_address['administrative_area_level_1_sn'] = results[0].address_components[i].short_name;
        this.state = results[0].address_components[i].short_name;
        break;
      case 'country':
        google_address['country_ln'] = results[0].address_components[i].long_name;
        google_address['country_sn'] = results[0].address_components[i].short_name;
        break;
      case 'postal_code':
        google_address['postal_code_ln'] = results[0].address_components[i].long_name;
        google_address['postal_code_sn'] = results[0].address_components[i].short_name;
        this.zipcode = results[0].address_components[i].short_name;
        break;
      case 'postal_code_suffix':
        google_address['postal_code_suffix_ln'] = results[0].address_components[i].long_name;
        google_address['postal_code_suffix_sn'] = results[0].address_components[i].short_name;
        break;
      }

    }
    google_address.formatted_address = results[0].formatted_address;
    //this.displayAddress = results[0].formatted_address;
    //this.full_address = this.displayAddress;
    google_address['id'] = this.pc.clientId;
    google_address.policyOwnerInfo['policyOwnerCity'] = this.city;
    google_address.policyOwnerInfo['policyOwnerZipcode'] = this.zip;
    google_address.policyOwnerInfo['policyOwnerState'] = this.state;

    console.log('google_address', google_address);
    await this.pc.saveUpdateClientData(google_address);
    this.addresscomponent = google_address;
    //this.handleAddressChange(this.displayAddress);
    return true;
  }
}

