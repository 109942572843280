export const environment = {
  production: true,
  envname: "prod",
  PASSCODESHA2048: '$W%Ld0os$3',
  googleMapApiKey: 'AIzaSyDt2HM0PNAkw1XY5anITxTQt9L_EVAPwCE',
  googleAnalyticsTrackingCode: 'GTM-WMBC6G9',
  Nest_API_URL: 'https://app.getmybubble.com/api',
  carrierErrorRedirectURL: "http://stage-www.getmybubble.io/",
  BO_URL: 'https://bo.getmybubble.co/api/app-tracking/',
  envObject: {
    devint: {
      url: "http://dev-int.getmybubble.co",
      paclifeUrl: "https://stage.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/fetch1"
    },
    uat: {
      url: "http://uat.getmybubble.co",
      paclifeUrl: "https://production.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/prod/pfetch1"
    },
    app: {
      url: "http://www.getmybubble.com",
      paclifeUrl: "https://production.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/prod/pfetch1"
    },
    stageapp: {
      url: "http://stage.getmybubble.co",
      paclifeUrl: "https://stage.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/fetch1"
    },
    track2: {
      url: "http://track2.getmybubble.co",
      paclifeUrl: "https://stage.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/fetch1"
    },
    track3: {
      url: "http://track3.getmybubble.co",
      paclifeUrl: "https://stage.getmybubble.io",
      api2Domain: "https://api2.getmybubble.io/fetch1"
    }
  }
};
