import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.css']
})
export class LeafletMapComponent implements AfterViewInit, OnChanges {

  private map;
  @Input() stateInput;
  @Input() countyInput;
  @Input() cityInput;
  @Input() latitudeInput;
  @Input() longitudeInput;
  @Input() stateLongNameInput;
  @Input() lifescoreInput;
  @Input() type;
  @Input() color;
  @Input() zipcode;
  @Output() colorSelected = new EventEmitter();
  api_url = 'https://app.getmybubble.co/api/alpha/v1/chartdata/';
  headers = new HttpHeaders({
    Origin: this.api_url,
    'Access-Control-Allow-Origin': this.api_url,
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'content-type': 'text/plain',
    'x-api-key': 'Vu2jjlRMvf3UqeGLjfwjnaVtOp0Wrl1i1HvGI3Yc',
    chart_data_request: 'chart_data'
  });

  state_color_palate = [
    '#FF0000',
    '#FF6900',
    '#FF9E00',
    '#F7FF00',
    '#C2FF00',
    '#7CFF00',
    '#35FF00',
    '#41ab5d',
    '#006837',
    '#006837',
  ];
  county_color_palate = [
    '#FF0000',
    '#FF6900',
    '#FF9E00',
    '#F7FF00',
    '#C2FF00',
    '#7CFF00',
    '#35FF00',
    '#41ab5d',
    '#006837',
    '#006837',
  ];
  _div: any;
  gradeLimitHashMap = {
    'poorHealthScoreState': [750, 775, 800, 815, 830, 850, 870, 880, 951, 980],
    'poorHealthScore': [660, 751, 775, 801, 826, 851, 876, 901, 925, 950],
    'householdIncome': [15000, 45000, 60000, 75000, 95000, 110000, 135000, 150000, 175000, 200000],
    'urbanScore': [0, 10, 20, 30, 40, 50, 60, 70, 85, 100],
    'distressScore': [6, 8, 9, 10, 12, 14, 16, 18, 19]
  };
  geojson: any;
  chartConfig: {
    chartCategory: string,
    chartTitle: string,
    setViewCoordinates: any,
    gradeLimitArray: any,
    zoomLevel: number,
    styleId: string,
    tileSize: number,
    zoomOffset: number,
    tip: string,
    chartData: string,
    scoreType: string
  };

  public L = null;

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef) {

    if (isPlatformBrowser(platformId)) {
      this.L = L;
    }
  }

  ngAfterViewInit(): void {

    const blockerDiv = this.elRef.nativeElement.querySelector('.block-interaction');
    blockerDiv.style.height = this.elRef.nativeElement.querySelector('.map-frame').offsetHeight + 'px';




    this.chartConfig = {
      chartCategory: 'County',
      chartTitle: 'Bubble Health Score',
      setViewCoordinates: [this.latitudeInput, this.longitudeInput],
      gradeLimitArray: this.gradeLimitHashMap['poorHealthScore'],
      zoomLevel: 1,
      styleId: 'mapbox/light-v9',
      tileSize: 512,
      zoomOffset: -1,
      tip: 'Hover over a county',
      chartData: '',
      scoreType: ''
    };

    this.map = this.L.map('map', {
      center: [this.latitudeInput, this.longitudeInput],
      zoom: 3,
      zoomControl: false,
      scrollWheelZoom: false
    });


    // this.map.on('load', () => this.addLegend());
    // this.addLegend();
    setTimeout(() => {
      this.addLegend();
    }, 3000);

    this.getChartData();
  }

  addLegend() {

    const legend = L.control({ position: 'bottomright' });
    legend.onAdd = function (map) {
      this._div = L.DomUtil.create('div', 'ng-legend');
      this.update();
      return this._div;
    };
    legend.update = function (props) {
      const grades = [750, 775, 800, 815, 830, 850, 870, 880, 951, 980];//chartConfig.gradeLimitArray;
      const labels = [];
      let from;
      let to;
      this._div.innerHTML =
        `<div style="width: 100% !important;
                        padding: 2px 15px 0px 15px !important;
                        background-color:rgba(0,0,0,0.5) !important;
                        color: #ffffff !important;">
          <div class="labels">
            <div class="min" style="float: left;">Very Low</div>
            <div class="max" style="float: right;">Very High</div>
          </div><br>`;
      const uniqColorsSet = new Set([
        '#FF0000',
        '#FF6900',
        '#FF9E00',
        '#F7FF00',
        '#C2FF00',
        '#7CFF00',
        '#35FF00',
        '#41ab5d',
        '#006837',
        '#006837',
      ]);
      for (const item of uniqColorsSet) {
        labels.push('<li style="display:inline-block;width:10%;height: 6px;background-color:' + item + '">' + '</li> ');
      }
      this._div.innerHTML +=
        '<ul style="list-style-type:none;display:flex;justify-content: center;background: rgba(0,0,0,0.5) !important;margin-bottom: 0px;padding-bottom:8px;margin-block-start:0em;padding-inline-start:0px;">' +
        labels.join('') +
        '</ul></div>';

      // this._div.innerHTML = labels.join("<br>");
    };
    legend.addTo(this.map);

    // setTimeout(() => {
    const legendElem = this.elRef.nativeElement.querySelector('.ng-legend');
    legendElem.style.color = 'red';
    legendElem.style.display = 'block';
    legendElem.style.margin = '0px';
    legendElem.style.float = 'none';
    legendElem.style.width = '100%';
    // legendElem.style.margin = "0px auto !important";
    /* let leafElemRight = this.elRef.nativeElement.querySelector('.leaflet-right');
    leafElemRight.style.width = "100%"; */
    this.elRef.nativeElement.getElementsByClassName('leaflet-right')[1].style.width = '100%';

    // }, 5000);
  }

  private initMap(chartData: any): void {

    const county_data = chartData;

    // this.map.setView([this.latitudeInput, this.longitudeInput], 3);

    const tiles = this.L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
      {
        maxZoom: 18,
        attribution:
          '&nbsp;&copy;&nbsp;Bubble Homescore',
        id: this.chartConfig.styleId,
        tileSize: this.chartConfig.tileSize,
        zoomOffset: this.chartConfig.zoomOffset
      }
    );

    /* <div style="width: 15vw !important; padding: 2px 15px 0px 15px !important;background-color:rgba(0,0,0,0.5) !important; color: #ffffff !important;">&nbsp;&copy;&nbsp;bubble</div> */
    tiles.addTo(this.map);
    if (this.countyInput == '') {
      this.countyInput = this.cityInput;
    }
    let countyName = this.countyInput;
    countyName = countyName.replace(' County', '');

    let countyColor = '';
    this.geojson = this.L.geoJSON(county_data, {
      style: (feature) => {
        let score = feature.properties.health_score;
        if (this.chartConfig.chartCategory == 'State') {
          score = feature.properties.health_score * 10;
        }

        if (this.chartConfig.scoreType == 'HouseholdIncome') {
          score = feature.properties.household_income;
        } else if (this.chartConfig.scoreType == 'Urban') {
          score = feature.properties.pct_urban;
        } else if (this.chartConfig.scoreType == 'Distress') {
          score = feature.properties.pct_frequent_mental_distress;
        }

        countyColor = this.getColor(score);
        if (feature.county == countyName) {

          this.colorSelected.emit(countyColor);
        }
        return {
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.5,
          fillColor: (this.type == 'LifeScore') ? countyColor : this.color
        };
      },
      onEachFeature: (feature, layer) => (
        layer.on({
          mouseover: (e) => {

            // let county = this.countyInput.replace(' County', '');

            /* if (feature.properties.county_original == county){
              var popup = this.L.popup()
                        .setLatLng([this.latitudeInput, this.longitudeInput])
                        .setContent('Lifescore: ' + this.lifescoreInput)
                        .openOn(this.map);
            } */

            this.highlightFeature(e);
          },
          mouseout: (e) => {
            this.geojson.resetStyle(e.target);
          },
          click: (e) => {
            this.map.fitBounds(e.target.getBounds());
          },
        }))
    });

    this.map.addLayer(this.geojson);
    this.map.fitBounds(this.geojson.getBounds());
    /* this.map = this.L.map("map", {
      center: [ this.latitudeInput, this.longitudeInput],
      zoom: 3
    }); */
    setTimeout(() => {



      // this.map.setZoom(7);
      this.map.setView([this.latitudeInput, this.longitudeInput], 7);
      /* var popupMessage = `<p style="margin:0px;font-weight:bold;">${this.countyInput}, ${this.stateInput}</p>
                          <p style="margin:0px;">LifeScore: <strong>${this.lifescoreInput}<strong></p>`;
      this.L.popup()
        .setLatLng([this.latitudeInput, this.longitudeInput])
        .setContent(popupMessage)
        .openOn(this.map);
     // console.log("Leaflet popup message: ", 'Lifescore: ' + this.lifescoreInput) */
      this.changeLifescorePopup();
    }, 3000);
  }



  private highlightFeature(e) {


    const layer = e.target;
    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7,
    });
    /* if( layer.feature.properties.county == "Denver"){

    } */
    /* layer.bindPopup('<p>'+layer.feature.properties.county+'<br>'+this.lifescoreInput+'</p>');
    layer.on('mouseover', function() { layer.openPopup(); });
    layer.on('mouseout', function() { layer.closePopup(); }); */
    /* var popup = L.popup()
   .setLatLng(e.latlng)
   .setContent('Popup')
   .openOn(this.map); */
    // layer.bindPopup(feature.properties.Name+"("+feature.properties.Lat+","+feature.properties.Lon+")",);
  }


  getChartData() {

    const full_url = this.api_url + this.stateLongNameInput.toLowerCase().replace(' ', '_');

    this.http.get(full_url, { headers: this.headers }).subscribe(
      (data: any) => {

        this.initMap(data);
      },
      error => {

      }
    );
  }


  getColor(d) {

    d = d < 100 ? d * 10 : d;
    if (Number.isNaN(d) || d == null || d == '') {
      return '#D3D3D3';
    }
    const color_palate =
      this.chartConfig.chartCategory == 'State'
        ? this.state_color_palate
        : this.county_color_palate;
    return d > this.chartConfig.gradeLimitArray[9]
      ? color_palate[9]
      : d >= this.chartConfig.gradeLimitArray[8]
        ? color_palate[8]
        : d >= this.chartConfig.gradeLimitArray[7]
          ? color_palate[7]
          : d >= this.chartConfig.gradeLimitArray[6]
            ? color_palate[6]
            : d >= this.chartConfig.gradeLimitArray[5]
              ? color_palate[5]
              : d >= this.chartConfig.gradeLimitArray[4]
                ? color_palate[4]
                : d >= this.chartConfig.gradeLimitArray[3]
                  ? color_palate[3]
                  : d >= this.chartConfig.gradeLimitArray[2]
                    ? color_palate[2]
                    : d >= this.chartConfig.gradeLimitArray[1]
                      ? color_palate[1]
                      : d >= this.chartConfig.gradeLimitArray[0]
                        ? color_palate[0]
                        : color_palate[0];
  }

  changeLifescorePopup() {
    const popupMessage = `<p style="margin:0px;font-weight:bold;">${this.zipcode || this.countyInput}, ${this.stateInput}</p>
                          <p style="margin:0px;">Bubble ${this.type} &trade;: <strong>${this.lifescoreInput}<strong></p>`;
    this.L.popup({
      closeButton: false
    })
      .setLatLng([this.latitudeInput, this.longitudeInput])
      .setContent(popupMessage)
      .openOn(this.map);

    /* setTimeout(() => {
      let closeElem = this.elRef.nativeElement.querySelector('a.leaflet-popup-close-button');
      closeElem.style.display = "none";

    }, 500); */

  }

  ngOnChanges(changes: SimpleChanges) {
    // this.lifescoreInput = changes['lifescoreInput'].currentValue;
    this.changeLifescorePopup();
    if (changes['latitudeInput'] && (changes['latitudeInput'].previousValue != changes['latitudeInput'].currentValue)) {

      for (const property in changes) {
        if (property === 'stateInput') {
          this.stateInput = changes[property].currentValue;
        }
        if (property === 'countyInput') {
          this.countyInput = changes[property].currentValue;
        }
        if (property === 'latitudeInput') {
          this.latitudeInput = changes[property].currentValue;
        }
        if (property === 'longitudeInput') {
          this.longitudeInput = changes[property].currentValue;
        }
        if (property === 'stateLongNameInput') {
          this.stateLongNameInput = changes[property].currentValue;
        }
      }
      setTimeout(() => {
        this.chartConfig.setViewCoordinates = [this.latitudeInput, this.longitudeInput];

        this.getChartData();
      }, 500);

    }

    if (changes['stateInput']) {
      this.stateInput = changes['stateInput'].currentValue;
    }
    this.getChartData();
  }
}
